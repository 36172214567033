const onClick = (evt) => {
  const $jsHeader = $(evt.target).closest(".js-header-with-search");
  if ($jsHeader.length) {
    $(".header-search-input").hide();
    $jsHeader.find(".header-search-input").show();
  } else {
    $(".header-search-input").hide();
  }
};

export const setup = function () {
  $(document).on("click", onClick);
};
