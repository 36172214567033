const CARD_INPUT_ID = "stripe-card-input";
const FORM_SUBMIT_ID = "form-submit";
const INPUT_ERROR_ID = "stripe-card-input-error";
const HIDDEN_INPUT_ID = "stripe-card-input-hidden";

export function stripeCard() {
  const cardInput = document.getElementById(CARD_INPUT_ID);
  if (!cardInput) {
    return;
  }
  if (typeof Stripe === "undefined") {
    cardInput.classList.add("error");
    cardInput.innerHTML = "Could not load Stripe.js. Check browser extensions.";
    return;
  }
  cardInput.classList.remove("error");
  cardInput.innerHTML = "";
  const stripe = Stripe(window.CONFIG.stripePk);
  const submit = document.getElementById(FORM_SUBMIT_ID);
  const errorSpan = document.getElementById(INPUT_ERROR_ID);
  const hiddenInput = document.getElementById(HIDDEN_INPUT_ID);

  const elements = stripe.elements();
  const cardElement = elements.create("card");
  cardElement.mount(cardInput);
  cardElement.on("change", async function onChange(event) {
    if (event.complete) {
      hiddenInput.value = "";
      errorSpan.innerHTML = "";
      try {
        const { token, error } = await stripe.createToken(cardElement);
        ahoy.track("Entered stripe card", { token_id: token.id });
        if (token) {
          hiddenInput.value = token.id;
          return;
        }
        if (error) {
          errorSpan.innerHTML = error.message;
        }
      } catch (e) {
        errorSpan.innerHTML = "Error creating payment token";
        ahoy.track("Error creating payment token", { error: e });
      }
    } else if (event.error) {
      errorSpan.innerHTML = event.error.message;
      ahoy.track("Error from stripe input change event", {
        error_message: event.error.message,
      });
    } else {
      let eventPayload = "";
      try {
        eventPayload = JSON.stringify(event);
      } catch (e) {
        eventPayload = "Error converting event to JSON";
      }
      ahoy.track("Entered info for stripe", { event_payload: eventPayload });
    }
  });
}
