export const setup = () => {
  $(".js-faq-item").on("click", (evt) => {
    const $parent = $(evt.target).closest(".js-faq-item");
    const $answer = $parent.find(".js-faq-answer");
    const $toggle = $parent.find(".js-toggle");

    if ($answer.hasClass("hidden")) {
      $answer.removeClass("hidden");
      $toggle.addClass("rotate-90");
      const question = $parent.find(".js-faq-title").text();
      ahoy.track("Viewed faq", {
        question: question,
        pathname: window.location.pathname,
      });
      Track.gtagEvent("Viewed faq");
    } else {
      $answer.addClass("hidden");
      $toggle.removeClass("rotate-90");
    }
  });
};
