import Chartkick from "chartkick";
import Chart from "chart.js";

export const setup = () => {
  Chartkick.use(Chart);
  $("[data-chart-line]").each((idx, el) => {
    const endpoint = $(el).data("chart-line");
    const chart = new Chartkick.AreaChart(el, endpoint);
  });

  $("[data-chart-histogram]").each((idx, el) => {
    const endpoint = $(el).data("chart-histogram");
    const chart = new Chartkick.ColumnChart(el, endpoint);
  });
};
