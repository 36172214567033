import Cookies from "js-cookie";

const KEY = "PS_EXIT_SUBSCRIBE_MODAL_V1";

function exitIntent(callback) {
  let timer;

  function trackLeave(ev) {
    if (ev.clientY > 0) {
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(function () {
      timer = null;
      const showed = Cookies.get(KEY);
      if (!showed) {
        callback();
      }
    }, 300);
  }

  function trackEnter() {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  }

  const body = document.getElementsByTagName("body")[0];
  body.addEventListener("mouseleave", trackLeave);
  document.addEventListener("mouseenter", trackEnter);
}

export const setup = function () {
  const body = document.getElementsByTagName("body")[0];
  const modal = document.querySelector("[data-modal='subscribe-exit']");
  if (modal) {
    exitIntent(() => {
      Cookies.set(KEY, true, { expires: 1 });
      modal.classList.remove("hidden");
    });
  }
};
